<template>
  <div class="card mb-4">
    <div class="card-header pb-0">
      <a
        class="btn bg-gradient-warning mt-4 w-20"
        href="/form-one-true-data"
        type="button"
      ><i class="fas fa-plus"> </i> Create OTD</a
      >
    </div>
    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Owner
              </th> 
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Title
              </th> 
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Date
              </th>              
              <th class="text-secondary opacity-7">
              </th>
            </tr>
          </thead>
          <tbody>
          <tr v-for="(token, index) in tokens" :key="index">
            <td>
              <div class="d-flex px-2 py-1">
                <div>
                  <soft-avatar
                    :img="`${index==2?img1:img2}`"
                    size="sm"
                    border-radius="lg"
                    class="me-3"
                    alt="user1"
                  />
                </div>
                <div class="d-flex flex-column justify-content-center">
                  <h6 class="mb-0 text-sm">{{index==2?'Mariana Lemos':'CodeLoop'}}</h6>
                  <p class="text-xs text-secondary mb-0">
                    {{index==2?'mariana.lemos':'contato@codeloop.co'}}
                  </p>
                </div>
              </div>
            </td>

            <td class="align-middle text-center">
              <span class="text-secondary text-xs font-weight-bold">{{ token.title }}</span>
            </td>
           
            <td class="align-middle text-center">
            <span class="text-secondary text-xs font-weight-bold">{{ formatDate(token.created_at) }}</span>
            </td>
            <td class="align-middle">

              <!-- <button router-link :to="{name: 'One True Data Show', params: { uuid: token.uuid }}" class="text-secondary font-weight-bold text-xs" />  -->

              <router-link :to="{ name: 'One True Data Show', params: { uuid: token.uuid } }" class="text-secondary font-weight-bold text-xs" ><i class="fas fa-eye"></i></router-link>


              <!-- <a href="javascript:;" class="text-secondary font-weight-bold text-xs" data-toggle="tooltip" data-original-title="View token">
                <i class="fas fa-eye"></i>
              </a> -->
            </td>
          </tr>
          </tbody>

        </table>
      </div>
    </div>
  </div>
</template>

<script>
import SoftAvatar from "@/components/SoftAvatar.vue";

import img1 from "../../../assets/img/team-1.jpg";
import img2 from "../../../assets/img/team-2.jpg";
import img3 from "../../../assets/img/team-3.jpg";
import img4 from "../../../assets/img/team-4.jpg";
import axios from 'axios';


export default {
  name: "hybrid-token",
  created() {
    this.fetchTokens();
  },
  data() {
    return {
      img1,
      img2,
      img3,
      img4,
      tokens: [],
    };
  },
  methods: {
    async fetchTokens() {
      try {
        const response = await axios.get('https://core-bastion.codeloop.co/api/otd-index');
        this.tokens = response.data.data;
      } catch (error) {
        console.error('Error fetching tokens:', error);
      }
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');

      return `${month}/${day}/${year} ${hours}:${minutes}`;
    },
  },
  components: {
    SoftAvatar,
  },
};
</script>
<style>
.capitalize {
    text-transform: capitalize;
}
</style>